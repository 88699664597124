import { useCallback, useState } from 'react'
import { styled } from '@mui/material'
import Popover, { PopoverProps } from './Popover'

const TooltipContainer = styled('div')({
  maxWidth: '243px',
  fontSize: 14,
  padding: '0.6rem 1rem',
  lineHeight: '150%',
  fontWeight: 400,
  whiteSpace: 'pre-line'
})

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: string
}

export default function Tooltip({ text, ...rest }: TooltipProps) {
  return <Popover content={<TooltipContainer>{text}</TooltipContainer>} {...rest} />
}

export function MouseoverTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  )
}
